<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="createUser">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Full Name</label>
                    <input
                      required
                      type="text"
                      class="form-control"
                      v-model="user.name"
                      placeholder="Full Name"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      required
                      type="email"
                      class="form-control"
                      v-model="user.email"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>ID Number</label>
                    <input
                      required
                      type="text"
                      class="form-control"
                      v-model="user.id_number"
                      placeholder="ID/Passport Number"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.phone_number"
                      required
                      placeholder="Phone Number"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Role</label>
                    <select
                      class="form-control"
                      required
                      v-model="user.role_id"
                    >
                      <option
                        v-for="(role, i) in roles"
                        :value="role.id"
                        :key="`role-${i}`"
                        >{{ role.name }}</option
                      >
                    </select>


                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" v-if="isAgentRole">
                     <label>Agent Referral Code</label>
                     <input type="text" class="form-control" v-model="referralCodeValue" readonly />
                   </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group text-right">
                    <button class="btn btn-secondary">
                      <i class="lni-save mr-2"></i>
                      Create User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetchRoles();
    // this.referralCodeValue = this.referralCode;
    this.generateCode();

  },

  data() {
    return {
      roles: [],
      user: {
        name: "",
        email: "",
        role: "",
        id_number: "",
        phone_number: "",
        role_name:"",
        referal_code: ""
      },
      referralCodeValue: ''

    };
  },

  methods: {
    createUser() {
      this.$loader.start()
      this.referal_code = this.referralCodeValue;
      this.user.referal_code = this.referal_code;
      this.$axios
        .post("/api/v1/dashboard/users", this.user)
        .then((response) => {
          // console.log(response)
          if(response.data.errors) {
            const firstError = Object.values(response.data.errors)[0][0]; // Get the first error message
          this.$toast.error(firstError);
          this.$loader.stop();
          return;
          }
          this.$loader.stop()
          this.$router.push({
            name: "users.show",
            params: { id: response.data },
          });
        });
    },

    setUserRoleName() {
    const selectedRole = this.roles.find((role) => role.id === this.user.role_id);
    this.user.role_name = selectedRole ? selectedRole.name : '';
  },

    fetchRoles() {
      this.$loader.start()
      this.$axios.get("/api/v1/dashboard/users/create").then((response) => {
        this.roles = response.data;
        this.$loader.stop()
        this.loading = false;
      });
    },
    async generateCode() {

      const response = await this.$axios.get('/api/v1/dashboard/agents/generate-code');
      this.referralCodeValue = response.data.code;
      return response.data.code;

  }
  },
  watch: {
  roles: {
    handler() {
      this.setUserRoleName();
    },
    immediate: true,
  },
  user: {
    handler() {
      this.setUserRoleName();
    },
    immediate: true,
    deep: true,
  },
},
  computed:{
    isAgentRole() {
      const selectedRole = this.roles.find((role) => role.id === this.user.role_id);

      return selectedRole && (selectedRole.name === 'agent' || selectedRole.name === 'Agent');
    },

    referralCode() {
    return this.generateCode();
  }
    
}
  
};
</script>
